import React from 'react';
import { useState } from "react";
import "../../app.css";
import "./login.css";

const Login = () => {
//  const [user, setUser] = useState(null);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
//  const [error, setError] = useState(false);
//  const [success, setSuccess] = useState(false);
    
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
      const res = login(username, password);
      console.log (res);
//      setUser(res.data);
    } catch(err) {
      console.log(err);
    }
    
  }

  const login = async (username, password) => {
    let data = {};
    return data;
  }

    return (
      <div className="login">
        <form onSubmit={handleSubmit}>
          <span className="formTitle">Ridy Login</span>
          <input
            type="text"
            placeholder="username"
            onChange={(e) => setUsername(e.target.value)}
          />
          <input
            type="password"
            placeholder="password"
            onChange={(e) => setPassword(e.target.value)}
          />
          <button type="submit" className="submitButton">
            Login
          </button>
        </form>
      </div>
    );
  };

  export default Login;